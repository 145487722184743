import React, { useState, useEffect } from "react";
import ApiService from "../../services/ApiService";
import {
  formatDatestring,
  getDisplayDateFromStartEndDateString,
  getNumPostfix,
  randomString,
} from "../../utils";
import { toast } from "react-hot-toast";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const eventTitle = `CES 2024`;

export default function Request() {
  const [isShown, setIsShown] = useState(false);
  const [event, setEvent] = useState(null);
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });

  // events
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const api = new ApiService();
        const tag = "global";
        const response = await api.get(`landmark/events/getEvents/${tag}`);
        console.log(
          `fetchEvent() - landmark/events/getEvents/${tag}`,
          response
        );
        if (response && response.length && response[0].eventId) {
          // get specific event
          const thisEvent = response.find(
            (e) => e.title.toLowerCase() === eventTitle.toLowerCase()
          );
          setEvent(thisEvent);
        } else {
          throw "Couldn't get event";
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Couldn't load data from server");
      }
    };
    fetchEvent();
  }, []);
  const [form, setForm] = useState({
    options: [],
    firstName: "",
    lastName: "",
    company: "",
    jobTitle: "",
    email: "",
    businessGoals: "",
    referredBy: "",
    partners: [],
  });
  const onChangeInput = (input) => (e) => {
    setForm((form) => ({ ...form, [input]: e.target.value }));
  };
  const updateForm = (data) => {
    setForm((form) => ({ ...form, ...data }));
  };
  const partnerOnChange = (e) => {
    if (e.target.checked) {
      let arr = [...form.partners];
      arr.push(e.target.id);
      updateForm({ partners: arr });
    } else {
      updateForm({
        partners: form.partners.filter((item) => item !== e.target.id),
      });
    }
  };

  const clearForm = () => {
    setForm({
      options: [],
      firstName: "",
      lastName: "",
      company: "",
      jobTitle: "",
      email: "",
      businessGoals: "",
      referredBy: "",
      partners: [],
    });
  };

  const submitForm = async () => {
    if (!formValid) {
      console.log(`submitForm() - form is invalid`, form);
      return;
    }
    form.eventId = event.eventId;
    form.optionRequests = form.options;
    console.log(`submitForm()`, form);
    try {
      const api = new ApiService();
      const response = await api.post(`landmark/events/submitGlobalEventRsvp`, {
        form,
      });
      console.log(`submitForm() - response`, response);
      setTimeout(() => {
        // toast.success("Your response has been logged. Thank you!");
        setIsShown(true);
        clearForm(); // clear inputs
      }, 50);
      // setEvents(response);
    } catch (error) {
      console.error("Error submitting form", error);
      toast.error("An error occurred when submitting your response.");
    }
  };

  console.log(`event`, event);

  if (!event || !event.eventId) {
    return null;
  }

  let formValid = false;
  let formErrorMsg = ``;
  // validate form
  const validateForm = () => {
    const {
      options,
      firstName,
      lastName,
      company,
      jobTitle,
      email,
      businessGoals,
      referredBy,
      partners,
    } = form;
    // must select at least 1 options event or partner option
    if (options.length + partners.length === 0) {
      formErrorMsg = "Please select at least one event or partnership option";
      return false;
    }
    if (!firstName.length) {
      formErrorMsg = "Please enter your first name";
      return false;
    }
    if (!lastName.length) {
      formErrorMsg = "Please enter your last name";
      return false;
    }
    if (!company.length) {
      formErrorMsg = "Please enter your company";
      return false;
    }
    if (!jobTitle.length) {
      formErrorMsg = "Please enter your job title";
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.length || !emailRegex.test(email)) {
      formErrorMsg = "Please enter a valid email address";
      return false;
    }
    return true;
  };
  formValid = validateForm();
  const submitDisabledClass = formValid ? "" : "disabled";

  return (
    <>
      <div className="cap-width">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, x: 20, y: 0 }} // Set your initial state
          animate={inView ? { opacity: 1, y: 0, x: 0 } : {}} // Set your animation properties
          transition={{ duration: 0.9, ease: "easeOut" }}
        >
          <div></div>
        </motion.div>
        <div className="options-list">
          <div className="option-container" style={{ minHeight: "unset" }}>
            <div
              className="option-header"
              style={{ borderRadius: "8px", minHeight: "100px" }}
            ></div>
          </div>
          <div className="option-heading-text">
            To request an invitation to your preferred events, please select one
            or more from the lists below:
          </div>
          {event && event.options.length
            ? event.options.map((item, index) => {
                return (
                  <RequestEventOption
                    itemData={item}
                    key={index}
                    updateForm={updateForm}
                    form={form}
                  />
                );
              })
            : null}
        </div>
      </div>
      <div className="cap-width slightly-wider">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 0 }} // Set your initial state
          animate={inView ? { opacity: 1, y: 0 } : {}} // Set your animation properties
          transition={{ duration: 1.4, ease: "easeOut" }}
        >
          <div className="form-container" id="join">
            <div className="requestForm">
              <h3>Your Information</h3>
              <div className="requestForm__inputs">
                <label className="input__outer sm">
                  <h5>First Name</h5>
                  <div className="input">
                    <input
                      type="name"
                      value={form.firstName}
                      onChange={onChangeInput("firstName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Last Name</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={onChangeInput("lastName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Company</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.company}
                      onChange={onChangeInput("company")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Job title</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.jobTitle}
                      onChange={onChangeInput("jobTitle")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Email</h5>
                  <div className="input">
                    <input
                      type="email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Referred By (optional)</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.referredBy}
                      onChange={onChangeInput("referredBy")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Tell us about your business goals (optional)</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.businessGoals}
                      onChange={onChangeInput("businessGoals")}
                    />
                  </div>
                </label>
              </div>
              <h3>Request an Invitation</h3>
              {event && event.options.length
                ? event.options.map((item, index) => {
                    return (
                      <RequestEventOption
                        checkboxMode={true}
                        itemData={item}
                        key={index}
                        updateForm={updateForm}
                        form={form}
                      />
                    );
                  })
                : null}

              <h5 className="partner-text">
                Let's discuss! We welcome the opportunity to collaborate with
                partners on a variety of marketing, branding, and business
                development goals at CES 2024 and beyond. Let us know if you
                would be interested in any of the following:
              </h5>

              <label htmlFor="partnerEventSponsorship" className="check">
                <div className="check__box">
                  <input
                    type="checkbox"
                    id="partnerEventSponsorship"
                    checked={form.partners.some(
                      (p) => p === "partnerEventSponsorship"
                    )}
                    onChange={partnerOnChange}
                  />
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                      alt="icon"
                    />
                  </span>
                </div>
                <div className="check-label">
                  I’d like to discuss a potential Event Sponsorship
                </div>
              </label>
              <label htmlFor="partnerCustomEvent" className="check">
                <div className="check__box">
                  <input
                    type="checkbox"
                    id="partnerCustomEvent"
                    checked={form.partners.some(
                      (p) => p === "partnerCustomEvent"
                    )}
                    onChange={partnerOnChange}
                  />
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                      alt="icon"
                    />
                  </span>
                </div>
                <div className="check-label">
                  I’d like to discuss a custom event at CES 2024
                </div>
              </label>
              <label htmlFor="partnerIndustryTrendsBriefing" className="check">
                <div className="check__box">
                  <input
                    type="checkbox"
                    id="partnerIndustryTrendsBriefing"
                    checked={form.partners.some(
                      (p) => p === "partnerIndustryTrendsBriefing"
                    )}
                    onChange={partnerOnChange}
                  />
                  <span>
                    <img
                      src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                      alt="icon"
                    />
                  </span>
                </div>
                <div className="check-label">
                  I’d like to schedule an Industry Trends Briefing
                </div>
              </label>
              <div className="requestForm submit">
                {!isShown && (
                  <div className="submit-wrapper">
                    <button
                      type="submit"
                      onClick={submitForm}
                      className={`button pink-btn ${submitDisabledClass}`}
                    >
                      SUBMIT
                    </button>
                    {formErrorMsg.length ? (
                      <div className="form-invalid-msg">{formErrorMsg}</div>
                    ) : null}
                  </div>
                )}
              </div>
              <AnimatePresence>
                {/* {isShown && <SuccessModal setIsShown={setIsShown} />} */}
                {isShown && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <div className="submit-success-msg">
                      Thank you for your application to join our Dealmakers
                      Events @ CES 2024!
                      <br></br>
                      We are currently processing all requests for
                      participation. Due to capacity constraints, we are
                      unfortunately unable to accommodate everyone.
                      <br></br>
                      For those we are able to accommodate, we will get back to
                      you shortly with a confirmation including all logistic
                      details.
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      </div>
      <div style={{ height: "400px" }}></div>
    </>
  );

  return (
    <>
      <section className="request" id="events">
        <div className="preface-container">
          <div className="preface-text">
            By harnessing the powerful networks of Landmark Dealmakers and
            Social Innovation Summit, LMV @ CES will convene an engaged group of
            global innovators looking to drive impactful partnerships. You are
            warmly invited to apply to join this dynamic group in mingling,
            networking, and relationship-building as we navigate the future of
            digital engagement together.
          </div>
        </div>
        <div className="auto__container">
          <div className="request__inner">
            {/* <div className="request__inner-circle">
            <img
              src={process.env.PUBLIC_URL + "/images/circle.png"}
              alt="circle"
            />
          </div> */}

            <div className="requestForm empty">
              <h3>
                To request an invitation to your preferred events, please select
                one or more from the lists below:
              </h3>
            </div>
            <div className="requestForm one">
              <h3>Tuesday, January 9, 2024</h3>
              <div className="requestForm__row unified">
                {event && event.options.length
                  ? event.options.map((item, index) => {
                      return (
                        <RequestEventOption
                          itemData={item}
                          key={index}
                          updateForm={updateForm}
                          form={form}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
            <div className="requestForm two">
              <h3>Wednesday, January 10, 2024</h3>
              {/* <h5>Wednesday, January 10, 2024</h5> */}
              <div className="requestForm__row unified">
                {event && event.options.length
                  ? event.options.slice(4, 8).map((item, index) => {
                      return (
                        <RequestEventOption
                          itemData={item}
                          key={index}
                          updateForm={updateForm}
                          form={form}
                        />
                      );
                    })
                  : null}
              </div>
            </div>
            <div className="requestForm two">
              <h3>Your Information:</h3>
              <div className="requestForm__inputs">
                <label className="input__outer sm">
                  <h5>First Name</h5>
                  <div className="input">
                    <input
                      type="name"
                      value={form.firstName}
                      onChange={onChangeInput("firstName")}
                    />
                  </div>
                </label>
                <label className="input__outer sm">
                  <h5>Last Name</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.lastName}
                      onChange={onChangeInput("lastName")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Company</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.company}
                      onChange={onChangeInput("company")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Job title</h5>
                  <div className="input">
                    <input
                      type="text"
                      value={form.jobTitle}
                      onChange={onChangeInput("jobTitle")}
                    />
                  </div>
                </label>
                <label className="input__outer">
                  <h5>Email</h5>
                  <div className="input">
                    <input
                      type="email"
                      value={form.email}
                      onChange={onChangeInput("email")}
                    />
                  </div>
                </label>
              </div>
            </div>

            <div className="requestForm three">
              <h3>Want to Partner?</h3>
              <div className="requestForm__inputs">
                <label className="input__outer">
                  <h5 className="subheading-text">
                    Let's discuss! We welcome the opportunity to collaborate
                    with partners on a variety of marketing, branding, and
                    business development goals. Let us know if you would be
                    interested in a collaborating as follows (optional):
                  </h5>
                  <label htmlFor="partnerEventSponsorship" className="check">
                    <div className="check__box">
                      <input
                        type="checkbox"
                        id="partnerEventSponsorship"
                        checked={form.partners.some(
                          (p) => p === "partnerEventSponsorship"
                        )}
                        onChange={partnerOnChange}
                      />
                      <span>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/tick.svg"
                          }
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className="check-label">
                      I’d like to discuss potential partnership opportunities
                      with Landmark, Social Innovation Summit, or Dealmakers
                    </div>
                  </label>
                  <label htmlFor="partnerCustomEvent" className="check">
                    <div className="check__box">
                      <input
                        type="checkbox"
                        id="partnerCustomEvent"
                        checked={form.partners.some(
                          (p) => p === "partnerCustomEvent"
                        )}
                        onChange={partnerOnChange}
                      />
                      <span>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/tick.svg"
                          }
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className="check-label">
                      I’d like to discuss a custom event at CES 2024
                    </div>
                  </label>
                  <label
                    htmlFor="partnerIndustryTrendsBriefing"
                    className="check"
                  >
                    <div className="check__box">
                      <input
                        type="checkbox"
                        id="partnerIndustryTrendsBriefing"
                        checked={form.partners.some(
                          (p) => p === "partnerIndustryTrendsBriefing"
                        )}
                        onChange={partnerOnChange}
                      />
                      <span>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/icons/tick.svg"
                          }
                          alt="icon"
                        />
                      </span>
                    </div>
                    <div className="check-label">
                      I’d like to schedule an Industry Trends Briefing
                    </div>
                  </label>
                </label>
              </div>
            </div>
          </div>
          <div className="requestForm four">
            <div className="requestForm__inputs">
              <label className="input__outer">
                <h5>Referred By (optional):</h5>
                <div className="input">
                  <input
                    type="text"
                    value={form.referredBy}
                    onChange={onChangeInput("referredBy")}
                  />
                </div>
              </label>
              <label className="input__outer">
                <h5>
                  Landmark, Social Innovation Summit, and Dealmakers hosts
                  events across the country throughout the year. If you are
                  interested in receiving an invitation to other events, please
                  indicate your preferred city (optional):
                </h5>
                <div className="input">
                  <input
                    type="text"
                    value={form.businessGoals}
                    onChange={onChangeInput("businessGoals")}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="requestForm submit">
            {!isShown && (
              <div className="submit-wrapper">
                <button
                  type="submit"
                  onClick={submitForm}
                  className={`button submit-btn ${submitDisabledClass}`}
                >
                  Submit
                </button>
                {formErrorMsg.length ? (
                  <div className="form-invalid-msg">{formErrorMsg}</div>
                ) : null}
              </div>
            )}
          </div>
          <AnimatePresence>
            {/* {isShown && <SuccessModal setIsShown={setIsShown} />} */}
            {isShown && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
                exit={{ opacity: 0 }}
              >
                <div className="submit-success-msg">
                  Thank you. We are excited to welcome you at CES 2024!<br></br>
                  We will be in touch via email regarding invitation details.
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </section>
    </>
  );
}

const RequestEventOption = (props) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // This ensures the animation triggers only once when the element comes into view
  });
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.options];
      arr.push(props.itemData.optionId);
      props.updateForm({ options: arr });
      console.log(
        `RequestEventOption() - form options updated - option event added`,
        arr
      );
    } else {
      const adjustedArr = props.form.options.filter(
        (item) => item !== props.itemData.optionId
      );
      props.updateForm({
        options: adjustedArr,
      });
      console.log(
        `RequestEventOption() - form options updated - option event removed`,
        adjustedArr
      );
    }
  };

  let redesignMode = true;
  let checkboxMode = props.checkboxMode || false;

  const randomId = randomString(6);

  // don't show hidden options
  if (props.itemData.hidden) {
    return null;
  }

  // for the bottom form
  if (props.checkboxMode) {
    console.log(`props.itemData.timeString`, props.itemData.timeString);
    console.log(`props.itemData.dateString`, props.itemData.dateString);

    function removeLastSegment(inputString) {
      // Split the string by whitespace
      const segments = inputString.split(/\s+/);

      // Remove the last element (segment) and join the remaining segments back into a string
      const result = segments.slice(0, -1).join(" ");

      return result.trim(); // Trim any extra whitespace
    }

    return (
      <label htmlFor={randomId} className="check">
        <div className="check__box">
          <input
            type="checkbox"
            id={randomId}
            checked={checked}
            onChange={onChange}
          />
          <span>
            <img
              src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
              alt="icon"
            />
          </span>
        </div>
        <div className="check-label">
          {`${formatDatestring(props.itemData.dateString, {
            shortDay: true,
          })} | ${removeLastSegment(props.itemData.timeString)}`}
          <span className="hide-mob">{` | `}</span> {/* &nbsp */}
          <div
            className="show-mob"
            style={{ width: "100%", height: "8px" }}
          ></div>
          {`${props.itemData.title}`}
        </div>
      </label>
    );
  }

  if (redesignMode) {
    const [titlePrefix, title] = props.itemData.title.split(":");
    return (
      <motion.div
        ref={ref}
        initial={{ opacity: 0, x: 20, y: 0 }} // Set your initial state
        animate={inView ? { opacity: 1, y: 0, x: 0 } : {}} // Set your animation properties
        transition={{ duration: 0.9, ease: "easeOut" }}
      >
        <div className="option-container">
          <div className="option-header">
            <div className="text prefix">{titlePrefix}</div>
            <div className="text">{title}</div>
          </div>
          <div className="option-body">
            <div className="upper-container">
              <div className="date-time-container">
                <div className="date">{props.itemData.dateString}</div>
                <div className="date">{props.itemData.timeString}</div>
              </div>
              <div className="date-time-container text-center">
                <div className="date">{props.itemData.location}</div>
                {props.itemData.subLocation && (
                  <div className="date slim">
                    ({props.itemData.subLocation})
                  </div>
                )}
              </div>
            </div>
            <div className="description">{props.itemData.description}</div>
          </div>
        </div>
      </motion.div>
    );
  }

  if (checkboxMode) {
    return (
      <div className="req-item extended">
        <div className="req-item-content alt">
          <div className="req-item-upper">
            <div className="check__box adj">
              <input
                type="checkbox"
                id={randomId}
                checked={checked}
                onChange={onChange}
              />
              <span>
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                  alt="icon"
                />
              </span>
            </div>
            {/* <div className="req-item-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div> */}
            <div
              onClick={() => {
                const e = { target: { checked: !checked } };
                onChange(e);
              }}
              className="req-item-header"
            >
              <h5>{props.itemData.title}</h5>
              <p className="sm short-desc">
                <span>{props.itemData.timeString}</span>
                <span className="divider">{` | `}</span>
                <span>{props.itemData.location}</span>
              </p>
              <p className="sm long-desc">{props.itemData.description}</p>
            </div>
          </div>
          {/* <div className="req-item-lower">
        </div> */}
        </div>
      </div>
    );
  }
  return null;
  // return (
  //   <div className="req-item">
  //     <input type="checkbox" checked={checked} onChange={onChange} />
  //     <div className="req-item-content">
  //       <div className="req-item-upper">
  //         <div className="req-item-img">
  //           <img className="img-fluid" src={props.itemData.imageUrl} />
  //         </div>
  //         <div className="req-item-header">
  //           <h5>{props.itemData.title}</h5>
  //           <p className="sm short-desc">{props.itemData.shortDescription}</p>
  //           <p className="sm long-desc">{props.itemData.longDescription}</p>
  //         </div>
  //       </div>
  //       {/* <div className="req-item-lower">
  //       </div> */}
  //     </div>
  //   </div>
  // );
};

const RequestItem = (props) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      let arr = [...props.form.events];
      arr.push(props.itemData);
      props.updateForm({ events: arr });
    } else {
      props.updateForm({
        events: props.form.events.filter(
          (item) => item.id !== props.itemData.id
        ),
      });
    }
  };

  let checkboxMode = true;

  const randomId = randomString(6);

  const selectedClass = checked ? "selected" : "";

  if (checkboxMode) {
    return (
      <div className="sis-omni industrial-tech w-100">
        <div className="req-item">
          <div className={`req-item-content alt ${selectedClass}`}>
            <div className="req-item-upper">
              <div className="check__box adj">
                <input
                  type="checkbox"
                  id={randomId}
                  checked={checked}
                  onChange={onChange}
                />
                <span>
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/tick.svg"}
                    alt="icon"
                  />
                </span>
              </div>
              <div className="req-item-img">
                <img className="img-fluid" src={props.itemData.imageUrl} />
              </div>
              <div className="req-item-header">
                <h5>{props.itemData.title}</h5>
                <p className="sm">
                  {getDisplayDateFromStartEndDateString(
                    props.itemData.startDate,
                    props.itemData.endDate,
                    props.itemData.endDateEpoch
                  )}
                </p>
                <p className="sm">{props.itemData.location}</p>
              </div>
            </div>
            {/* <div className="req-item-lower">
        </div> */}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="requestFormItem">
      <div className="requestFormItem__inner">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <div className="requestFormItem__content">
          <div className="d-flex event-container">
            <div className="event-img">
              <img className="img-fluid" src={props.itemData.imageUrl} />
            </div>
            <div className="event-info">
              <h5>{props.itemData.title}</h5>
              <p className="sm">
                {getDisplayDateFromStartEndDateString(
                  props.itemData.startDate,
                  props.itemData.endDate,
                  props.itemData.endDateEpoch
                )}
              </p>
              <p className="sm">{props.itemData.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
